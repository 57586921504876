import React, { useEffect, useState, useContext } from 'react';
import AdminContext from "@context";
import defaultState from './state'

const useFacturacion = (defaultData, getValue) => {
	const { auth } = useContext(AdminContext);
	const [data, setData] = useState(defaultState)

	const update = (key, value) => {}

	return { auth, data, update }
}

export default useFacturacion
