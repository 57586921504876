import React, { useContext } from "react";
import { Layout } from "@layout";
import { Datos } from "@afiliado/Facturacion";
import { clabe } from "clabe-validator";

import {
	Grid,
	Flex,
	Col,
	Button,
	Box,
	Alert,
	Title,
	Text,
} from "@components";
import AdminContext from "@context";

function Facturacion() {
	const { auth } = useContext(AdminContext);
	const { username } = auth;

	return (
		<Layout title='Facturación'>
			<Grid className="pv:2">
				<Title className="mb:05">Información de Facturación</Title>

				<Flex>
					<Col md={10}>
						<Box className="mt:2">
							<Flex className="mb:2">
								<Alert type="info" align="center" className="mb:1 mr:1">
									<Text fs={12} fw={300}>
										Descuida tu información de facturación solamente la usaremos para
										enviar a tu cuenta las recompensas generadas.
									</Text>
								</Alert>
							</Flex>

							<Datos getValue={v => console.log(v)} />
						</Box>

						<Flex justify='start' className='mt:2'>
							<Button square primary disabled>guardar información</Button>
						</Flex>
					</Col>
				</Flex>


			</Grid>
		</Layout>
	);
}

export default Facturacion;
