import React from "react";
import useFacturacion from './hooks';
import {
	Grid,
	Flex,
	Col,
	Input,
	Select,
	Button,
	Alert,
	Title,
	SubTitle,
	Text,
} from "@components";

function Facturacion(defaultData, getValue) {
	const { data, update } = useFacturacion(defaultData, getValue);

	return (
		<>
			<Flex className="mb:2">
				<SubTitle>Captura tus datos</SubTitle>
				<Col md={15} className="ph:05 mb:1">
					<Input
						className="mb:1"
						value={data.titular}
						getValue={(v) => update("titular", v)}
						placeholder="titular de la cuenta"
						maxLength={50}
						capitalize
						onlyAlpha
					/>

					<Input
						className="mb:1"
						value={data.clabe}
						getValue={(v) => update("clabe", v)}
						placeholder="Cuenta clabe"
					/>

					<Input
						className="mb:1"
						value={data.confirmClabe}
						getValue={(v) => update("confirmClabe", v)}
						placeholder="Confirma la cuenta clabe"
					/>
					<Input
						className="mb:1"
						value={data.card}
						getValue={(v) => update("card", v)}
						placeholder="No. de Tarjeta"
						maxLength={10}
						isNumber
					/>

					<Select
						getValue={(v) => update("type", v)}
						placeholder="tipo de cuenta"
						value={data.type?.id}
						// options={servicios}
					/>
				</Col>
			</Flex>
		</>
	);
}

export default Facturacion;
